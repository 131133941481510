import React, { useEffect } from 'react'

const Services = () => {
   useEffect(()=>{
    sessionStorage.clear()
   },[])
  return (
    <section className="services section">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="section-title">
            <h2>We Offer Different Services To Improve Your Health</h2>
            <img src="img/section-img.png" alt="#" />
            <p>
              Lorem ipsum dolor sit amet consectetur adipiscing elit praesent
              aliquet. pretiumts
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 col-md-6 col-12">
          {/* Start Single Service */}
          <div className="single-service">
            <i className="icofont icofont-prescription" />
            <h4>
              <a    >General Treatment</a>
            </h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
              luctus dictum eros ut imperdiet.{" "}
            </p>
          </div>
          {/* End Single Service */}
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          {/* Start Single Service */}
          <div className="single-service">
            <i className="icofont icofont-tooth" />
            <h4>
              <a    >Teeth Whitening</a>
            </h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
              luctus dictum eros ut imperdiet.{" "}
            </p>
          </div>
          {/* End Single Service */}
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          {/* Start Single Service */}
          <div className="single-service">
            <i className="icofont icofont-heart-alt" />
            <h4>
              <a    >Heart Surgery</a>
            </h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
              luctus dictum eros ut imperdiet.{" "}
            </p>
          </div>
          {/* End Single Service */}
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          {/* Start Single Service */}
          <div className="single-service">
            <i className="icofont icofont-listening" />
            <h4>
              <a    >Ear Treatment</a>
            </h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
              luctus dictum eros ut imperdiet.{" "}
            </p>
          </div>
          {/* End Single Service */}
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          {/* Start Single Service */}
          <div className="single-service">
            <i className="icofont icofont-eye-alt" />
            <h4>
              <a    >Vision Problems</a>
            </h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
              luctus dictum eros ut imperdiet.{" "}
            </p>
          </div>
          {/* End Single Service */}
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          {/* Start Single Service */}
          <div className="single-service">
            <i className="icofont icofont-blood" />
            <h4>
              <a    >Blood Transfusion</a>
            </h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
              luctus dictum eros ut imperdiet.{" "}
            </p>
          </div>
          {/* End Single Service */}
        </div>
      </div>
    </div>
  </section>
  )
}

export default Services