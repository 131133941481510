import React from 'react'

const VideoSection = () => {
  return (
    <>
    <section className="why-choose section">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="section-title">
            <h2>We Offer Different Services To Improve Your Health</h2>
            <img src="img/section-img.png" alt="#" />
            <p>
            Building Healthier Lives Through Compassionate and Trusted Care
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-12">
          {/* Start Choose Left */}
          <div className="choose-left">
            <h3>Who We Are</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
              pharetra antege vel est lobortis, a commodo magna rhoncus. In quis
              nisi non emet quam pharetra commodo.{" "}
            </p>
            <p>
              Class aptent taciti sociosqu ad litora torquent per conubia
              nostra, per inceptos himenaeos.{" "}
            </p>
            <div className="row">
              <div className="col-lg-6">
                <ul className="list">
                  <li>
                    <i className="fa fa-caret-right" />
                    Maecenas vitae luctus nibh.{" "}
                  </li>
                  <li>
                    <i className="fa fa-caret-right" />
                    Duis massa massa.
                  </li>
                  <li>
                    <i className="fa fa-caret-right" />
                    Aliquam feugiat interdum.
                  </li>
                </ul>
              </div>
              <div className="col-lg-6">
                <ul className="list">
                  <li>
                    <i className="fa fa-caret-right" />
                    Maecenas vitae luctus nibh.{" "}
                  </li>
                  <li>
                    <i className="fa fa-caret-right" />
                    Duis massa massa.
                  </li>
                  <li>
                    <i className="fa fa-caret-right" />
                    Aliquam feugiat interdum.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* End Choose Left */}
        </div>
        <div className="col-lg-6 col-12">
          {/* Start Choose Rights */}
          <div className="choose-right">
            <div className="video-image">
              {/* Video Animation */}
              <div className="promo-video">
                <div className="waves-block">
                  <div className="waves wave-1" />
                  <div className="waves wave-2" />
                  <div className="waves wave-3" />
                </div>
              </div>
              {/*/ End Video Animation */}
              <a
               
                className="video video-popup mfp-iframe"
              >
                <i className="fa fa-play" />
              </a>
            </div>
          </div>
          {/* End Choose Rights */}
        </div>
      </div>
    </div>
  </section>

  </>
  )
}

export default VideoSection