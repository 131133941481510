import React from 'react'

const Features = () => {
  return (
    <section className="Feautes section">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="section-title">
            <h2>We Are Always Ready to Help You &amp; Your Family</h2>
            <img src="img/section-img.png" alt="#" />
            <p>
            Where Your Health and Well-Being Are Always Our Top Priority
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 col-12">
          {/* Start Single features */}
          <div className="single-features">
            <div className="signle-icon">
              {/* <i className="icofont icofont-ambulance-cross" /> */}
             
              <i class="fas fa-user-md icofont-doctor-alt"></i>
            </div>
            <h3>Highly Experienced Doctors</h3>
            <p>
            World-Class Doctors with Decades of Experience.
            </p>
          </div>
          {/* End Single features */}
        </div>
        <div className="col-lg-4 col-12">
          {/* Start Single features */}
          <div className="single-features">
            <div className="signle-icon">
            {/* <i class="icofont-search-document"></i> */}
            <i class="icofont-patient-file"></i>
            </div>
            <h3>Accurate Medical Reports</h3>
            <p>
            Reliable Medical Reports for Personalized Treatment Plans.
            </p>
          </div>
          {/* End Single features */}
        </div>
        <div className="col-lg-4 col-12">
          {/* Start Single features */}
          <div className="single-features last">
            <div className="signle-icon">
              {/* <i className="icofont icofont-stethoscope" /> */}
              <i class="icofont-laboratory"></i>
            </div>
            <h3>Reliable Lab Tests for Accurate Diagnosis</h3>
            <p>
            Cutting-Edge Diagnostics to Empower Your Health Journey.
            </p>
          </div>
          {/* End Single features */}
        </div>
      </div>
    </div>
  </section>
  )
}

export default Features