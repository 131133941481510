import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const [activeTab, setActiveTab] = useState(''); // Initial active tab

    const navigate = useNavigate();

    const routers = (route) => {
        navigate(`/${route}`)
        setActiveTab(route)
    }
    return (
        <>

            <header className="header">
                <div className="header-inner">
                    <div className="container">
                        <div className="inner">
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-12">
                                    {/* Start Logo */}
                                    <div className="logo">
                                        <a >
                                            <img width={'80%'} src="img/logo.png" alt="#" />
                                        </a>
                                    </div>
                                    {/* End Logo */}
                                    {/* Mobile Nav */}
                                    <div className="mobile-nav" />
                                    {/* End Mobile Nav */}
                                </div>
                                <div className="col-lg-7 col-md-9 col-12">
                                    {/* Main Menu */}
                                    <div className="main-menu">
                                        <nav className="navigation">
                                            <ul className="nav menu">
                                                <li onClick={()=>routers('')} className={activeTab === '' ? 'active' : ''} >
                                                    <a >
                                                        Home
                                                    </a>
                                                    {/* <ul className="dropdown">
                                                        <li>
                                                            <a href="index.html">Home Page 1</a>
                                                        </li>
                                                    </ul> */}
                                                </li>
                                                {/* <li>
                                                    <a href="#">Doctos </a>
                                                </li> */}
                                                <li onClick={()=>routers('servces')}  className={activeTab === 'servces' ? 'active' : ''}>
                                                    <a >Services </a>
                                                </li>
                                                {/* <li>
                                                    <a href="#">
                                                        Pages <i className="icofont-rounded-down" />
                                                    </a>
                                                    <ul className="dropdown">
                                                        <li>
                                                            <a href="404.html">404 Error</a>
                                                        </li>
                                                    </ul>
                                                </li> */}
                                                <li onClick={()=>routers('blogs')}  className={activeTab === 'blogs' ? 'active' : ''}>
                                                    <a>
                                                        Blogs
                                                    </a>
                                                </li>
                                                <li onClick={()=>routers('servces')}  className={activeTab === 'aboutus' ? 'active' : ''}>
                                                    <a >About Us</a>
                                                </li>
                                                <li onClick={()=>routers('contactus')}  className={activeTab === 'contactus' ? 'active' : ''}>
                                                    <a >Contact Us</a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                    {/*/ End Main Menu */}
                                </div>
                                <div className="col-lg-2 col-12">
                                    <div className="get-quote">
                                        <a  className="btn">
                                            Book Appointment
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*/ End Header Inner */}
            </header>

        </>
    )
}

export default Header