import React, { useEffect } from 'react'
import Slider from './../screens/Slider';
import Schedule from './../screens/Schedule';
import Features from './../screens/Features';
import Digits from './../screens/Digits';
import VideoSection from './../screens/VideoSection';
const Landing = () => {

useEffect(() => {
  // Check if the page has been reloaded
  const isReload = sessionStorage.getItem('isReloaded');
  
  if (!isReload) {
    // If it's the first load, reload the page and set the flag
    sessionStorage.setItem('isReloaded', 'true');
    window.location.reload(); // Reload the page
  }
  
  // If the page has been reloaded, the component continues as normal
}, []);


  return (
    <>
  {/* Preloader */}
  
  {/* End Preloader */}
  {/* Get Pro Button */}
  {/* <ul className="pro-features">
    <a className="get-pro" href="#">
      Get Pro
    </a>
    <li className="big-title">Pro Version Available on Themeforest</li>
    <li className="title">Pro Version Features</li>
    <li>2+ premade home pages</li>
    <li>20+ html pages</li>
    <li>Color Plate With 12+ Colors</li>
    <li>Sticky Header / Sticky Filters</li>
    <li>Working Contact Form With Google Map</li>
    <div className="button">
      <a
        href="http://preview.themeforest.net/item/mediplus-medical-and-doctor-html-template/full_screen_preview/26665910?_ga=2.145092285.888558928.1591971968-344530658.1588061879"
        target="_blank"
        className="btn"
      >
        Pro Version Demo
      </a>
      <a
        href="https://themeforest.net/item/mediplus-medical-and-doctor-html-template/26665910"
        target="_blank"
        className="btn"
      >
        Buy Pro Version
      </a>
    </div>
  </ul> */}
  {/* Header Area */}



<Slider />
<Schedule />
<Features />
<Digits />
<VideoSection />
  <section className="call-action overlay" data-stellar-background-ratio="0.5">
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-12">
          <div className="content">
            <h2>Do you need Emergency Medical Care? Call @ 1234 56789</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
              porttitor dictum turpis nec gravida.
            </p>
            <div className="button">
              <a  className="btn">
                Contact Now
              </a>
              <a  className="btn second">
                Learn More
                <i className="fa fa-long-arrow-right" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="portfolio section">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="section-title">
            <h2>We Maintain Cleanliness Rules Inside Our Hospital</h2>
            <img src="img/section-img.png" alt="#" />
            <p>
              Lorem ipsum dolor sit amet consectetur adipiscing elit praesent
              aliquet. pretiumts
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12 col-12">
          <div className="owl-carousel portfolio-slider">
            <div className="single-pf">
              <img src="img/pf1.jpg" alt="#" />
              <a  className="btn">
                View Details
              </a>
            </div>
            <div className="single-pf">
              <img src="img/pf2.jpg" alt="#" />
              <a  className="btn">
                View Details
              </a>
            </div>
            <div className="single-pf">
              <img src="img/pf3.jpg" alt="#" />
              <a    className="btn">
                View Details
              </a>
            </div>
            <div className="single-pf">
              <img src="img/pf4.jpg" alt="#" />
              <a    className="btn">
                View Details
              </a>
            </div>
            <div className="single-pf">
              <img src="img/pf1.jpg" alt="#" />
              <a    className="btn">
                View Details
              </a>
            </div>
            <div className="single-pf">
              <img src="img/pf2.jpg" alt="#" />
              <a    className="btn">
                View Details
              </a>
            </div>
            <div className="single-pf">
              <img src="img/pf3.jpg" alt="#" />
              <a    className="btn">
                View Details
              </a>
            </div>
            <div className="single-pf">
              <img src="img/pf4.jpg" alt="#" />
              <a    className="btn">
                View Details
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>


 



</>
  )
}

export default Landing